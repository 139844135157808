<template>
  <SmoothModal
    :model-value="isModalOpen"
    width="440px"
    :is-description-visible="false"
    :has-header="false"
    @update:model-value="isModalOpen = false"
  >
    <div class="add-key-body">
      <AppIcon name="warning-modal" size="48px" is-img-tag />
      <AppText
        variant="div"
        size="17px"
        class="font-medium"
        mt="20px"
        mb="16px"
      >
        {{ t('merchantSettings.modalToDeleteApiKey.title') }}
      </AppText>
      <AppText variant="div" :line-height="1.5" mb="30px">
        {{ t('merchantSettings.modalToDeleteApiKey.description') }}
      </AppText>
      <div class="d-flex" style="gap: 20px;">
        <FButton type="danger" is-full-width @click="onDelete">
          {{ t('common.confirm') }}
        </FButton>
        <FButton type="plain" is-full-width @click="isModalOpen = false">
          {{ t('common.cancel') }}
        </FButton>
      </div>
    </div>
  </SmoothModal>
</template>

<script setup>
import SmoothModal from '@/components/SmoothModal';

import { deleteApiKey } from '@/views/merchant/MerchantSettings/api';

const props = defineProps({
  keyId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['key-deleted']);

const isModalOpen = defineModel({ type: Boolean, default: false });

const onDelete = async () => {
  const { isSuccess } = await deleteApiKey(props.keyId);

  if (isSuccess) {
    emit('key-deleted');
    isModalOpen.value = false;
  }
};
</script>

<style lang="scss" scoped>
.add-key-body {
  padding-top: 30px;
}
</style>
