<template>
  <div class="counter">
    <div class="line" />
    <AppText size="12px" class="font-medium" :opacity="0.5" type="uppercase">
      {{ count }}/{{ total }} {{ t('merchantSettings.api.keysUsed') }}
    </AppText>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'KeysCounter',
  props: {
    count: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 10,
    },
  },
  setup(props) {
    const lineWidth = computed(() => `${(props.count / props.total) * 100}%`);

    return { lineWidth };
  },
};
</script>

<style lang="scss" scoped>
.counter {
  display: flex;
  align-items: center;
  gap: 10px;

  .line {
    width: 20px;
    height: 8px;
    background-color: var(--color-E6E6E6);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: v-bind(lineWidth);
      height: 8px;
      background-color: var(--color-primary);
      border-radius: 1px;
      @include transition-base('width');
    }
  }
}
</style>
