<template>
  <div class="api-keys">
    <SettingsHeader :title="t('merchantSettings.api.title')" :last-updated="lastUpdated" />
    <div class="actions">
      <FButton
        type="text"
        size="x-small"
        icon="plus"
        @click="onGenerate"
      >
        <AppText class="font-medium">
          {{ t('merchantSettings.api.generateKey') }}
        </AppText>
      </FButton>

      <KeysCounter :count="items.length" />
    </div>

    <SimpleTable :data="items" :columns="keyColumns" :is-loading="isLoading">
      <template #main="{ item, column, row }">
        <div v-if="column.name === 'key'" class="key-cell">
          <TooltipCopy :text="item" is-min-gap>
            <AppText>{{ item }}</AppText>
          </TooltipCopy>
          <AppText :opacity="0.4">
            {{ row.memo || '-' }}
          </AppText>
        </div>

        <AppText v-if="column.name === 'ip'" :opacity="0.4">
          {{ item || t('common.any') }}
        </AppText>

        <AppText v-if="column.name === 'lastUsed'" :opacity="item ? 1 : 0.4">
          {{ getPeriodAgo(item) || t('common.never') }}
        </AppText>

        <FButton
          v-if="column.name === 'action'"
          type="plain"
          size="x-small"
          @click="onOpenViewLogsModal(row._id, row.key)"
        >
          <AppIcon name="polygon" size="12px" is-img-tag />
          <AppText class="font-mono">
            {{ t('merchantSettings.api.table.actionText') }}
          </AppText>
        </FButton>

        <FButton
          v-if="column.name === 'del'"
          class="del-button"
          is-icon
          icon="trash"
          type="transparent"
          @click="openDeleteModal(row._id)"
        />
      </template>

      <template #nodata>
        <td colspan="20">
          <div v-if="!isLoading" class="no-data">
            <div class="icon-wrap">
              <AppIcon name="key-filled" size="18px" is-img-tag />
            </div>
            <AppText size="14px" :opacity="0.5">
              {{ t('merchantSettings.api.table.nodata') }}
            </AppText>
          </div>
        </td>
      </template>
    </SimpleTable>

    <ModalToAddAPIkey
      v-model="isCreateModalOpen"
      @key-added="$emit('update')"
    />

    <ModalToDeleteApiKey
      v-model="isDeleteModalOpen"
      :key-id="keyIdToDelete"
      @key-deleted="$emit('update')"
    />

    <ModalToViewKeyLogs
      v-model="isViewLogsModalOpen"
      :key-id="keyIdToViewLogs"
      :key-name="keyNameToViewLogs"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';

import SimpleTable from '@/components/MainTable/SimpleTable.vue';
import TooltipCopy from '@/components/TooltipCopy.vue';

import { getPeriodAgo } from '@/utils/functions';
import { useSettings } from '@/composables/useSettings';
import useNotifications from '@/composables/useNotifications';
import { i18n } from '@/plugins/localization';

import SettingsHeader from '../../components/SettingsHeader.vue';

import ModalToAddAPIkey from './ModalToAddAPIkey.vue';
import ModalToDeleteApiKey from './ModalToDeleteApiKey.vue';
import ModalToViewKeyLogs from './ModalToViewKeyLogs.vue';
import KeysCounter from './KeysCounter.vue';

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
});

defineEmits(['update']);

const { t } = i18n.global;
const { settings } = useSettings();

const isLoading = ref(false);
const isCreateModalOpen = ref(false);
const isDeleteModalOpen = ref(false);
const isViewLogsModalOpen = ref(false);

const keyColumns = [
  { name: 'key', title: t('merchantSettings.api.table.key'), width: 500 },
  { name: 'ip', title: t('merchantSettings.api.table.ip'), width: 200 },
  { name: 'lastUsed', title: t('merchantSettings.api.table.lastUsed'), width: 120 },
  { name: 'action', title: t('merchantSettings.api.table.actions'), width: 190 },
  { name: 'del', title: '', width: 110 },
];

const lastUpdated = computed(() => settings.value.apiKeysUpdatedAt);

const keyIdToDelete = ref('');

const openDeleteModal = (id) => {
  keyIdToDelete.value = id;
  isDeleteModalOpen.value = true;
};

const onGenerate = () => {
  if (props.items.length >= 10) {
    const { addNotification } = useNotifications();
    addNotification({ text: t('merchantSettings.notification.generateNewApiKeymaxAmountWarning'), config: { color: 'error' } });
  } else {
    isCreateModalOpen.value = true;
  }
};

const keyIdToViewLogs = ref('');
const keyNameToViewLogs = ref('');

const onOpenViewLogsModal = (id, name) => {
  keyIdToViewLogs.value = id;
  keyNameToViewLogs.value = name;
  isViewLogsModalOpen.value = true;
};
</script>

<style lang="scss" scoped>
.api-keys:deep(.table) {
  height: auto;
}
.api-keys:deep(.table__row__cell) {
  height: unset;
  padding: 10px 0;
}

.actions {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 30px 0;
}

.key-cell {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 10px 0;
}

.del-button {
  width: 40px;
  height: 40px;
  margin: 10px 0;
  background-color: var(--color-white-0);
  @include transition-base(background-color);
  :deep(.icon path) {
    fill: var(--color-black-04);
    @include transition-base('stroke');
  }

  &:hover {
    background-color: var(--color-error-01);
    :deep(.icon path) {
      fill: var(--color-error);
    }
  }
}

.no-data {
  width: 100%;
  @include flex-center;
  flex-direction: column;
  gap: 20px;
  padding: 100px 0;

  .icon-wrap {
    width: 40px;
    height: 40px;
    @include flex-center;
    background: var(--color-F7F7F7);
    border-radius: 50%;

    :deep(.icon path) {
      fill: rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
