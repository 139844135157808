<template>
  <div class="section">
    <AppText variant="div" size="15px" class="title font-medium">
      {{ title }}
    </AppText>
    <div class="switches">
      <div
        v-for="(item, key) in items"
        :key="key"
        class="switch"
      >
        <div class="d-flex align-items-center" style="gap: 10px;">
          <AppText>
            {{ item.label }}
          </AppText>
          <AppTooltip
            v-if="item.tip"
            text-align="left"
            width="264px"
            cursor="default"
          >
            <template #text>
              {{ item.tip }}
            </template>

            <AppIcon name="info-circle-big" size="14px" :opacity="0.4" is-img-tag />
          </AppTooltip>
        </div>
        <FRadioButton
          v-model="item.value"
          :options="options"
          height="32px"
          @change="onChange(key, !item.value)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

import AppTooltip from '@/components/AppTooltip.vue';

import { i18n } from '@/plugins/localization';

export default {
  name: 'NotificationsSection',
  components: {
    AppTooltip,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const { t } = i18n.global;
    const options = computed(() => [
      { text: t('common.none'), value: false },
      { text: t('merchantSettings.notifications.emailNotification'), value: true },
    ]);

    const onChange = (key, val) => {
      emit('change', { name: key, value: val });
    };

    return { options, onChange };
  },
};
</script>

<style lang="scss" scoped>
.section {
  max-width: 500px;
  padding: 10px 0;
  display: flex;
  border-bottom: 1px solid #E6E6E6;

  .title {
    width: 140px;
    height: 60px;
    display: flex;
    align-items: center;
  }

  .switches {
    width: 380px;

    .switch {
      height: 60px;
      width: 100%;
      border-bottom: 1px solid #E6E6E6;
      padding: 12px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
