<template>
  <div class="settings-notifications">
    <SettingsHeader :title="t('merchantSettings.notifications.title')" :last-updated="lastUpdated" />
    <div class="body">
      <NotificationsSection
        :title="t('merchantSettings.notifications.access.title')"
        :items="accountOptions"
        @change="onUpdateNotification"
      />

      <NotificationsSection
        :title="t('merchantSettings.notifications.payments.title')"
        :items="paymentOptions"
        @change="onUpdateNotification"
      />

      <NotificationsSection
        v-if="isPlanPaid"
        :title="t('merchantSettings.notifications.subscription.title')"
        :items="subscriptionOptions"
        @change="onUpdateNotification"
      />

      <NotificationsSection
        v-if="!isPlanBlack && !isPlanIo"
        :title="t('merchantSettings.notifications.quota.title')"
        :items="quotaOptions"
        @change="onUpdateNotification"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, watch } from 'vue';

import { accountTypes } from '@/common/data';
import { useUser } from '@/composables/useUser';
import { i18n } from '@/plugins/localization';

import { updateNotification } from '../../api';
import SettingsHeader from '../../components/SettingsHeader.vue';

import NotificationsSection from './NotificationsSection.vue';

const emit = defineEmits(['update-data']);
defineProps({
  lastUpdated: {
    type: String,
    default: null,
  },
});

const { t } = i18n.global;
const { notifications, user } = useUser();

const accountOptions = reactive({
  successfulLogin: {
    label: t('merchantSettings.notifications.access.successfulLogin'),
    value: notifications.value?.successfulLogin,
  },
});

const paymentOptions = reactive({
  paymentSighted: {
    label: t('merchantSettings.notifications.payments.paymentSighted'),
    value: notifications.value?.paymentSighted,
  },
  paymentReceived: {
    label: t('merchantSettings.notifications.payments.paymentReceived'),
    value: notifications.value?.paymentReceived,
  },
  underpaidSighted: {
    label: t('merchantSettings.notifications.payments.underpaidSighted'),
    value: notifications.value?.underpaidSighted,
  },
});

const subscriptionOptions = reactive({
  subscriptionActivated: {
    label: t('merchantSettings.notifications.subscription.subscriptionActivated'),
    value: notifications.value?.subscriptionActivated,
  },
  subscriptionTerminated: {
    label: t('merchantSettings.notifications.subscription.subscriptionTerminated'),
    value: notifications.value?.subscriptionTerminated,
    tip: t('merchantSettings.notifications.sinscriptionsTerminated.tip'),
  },
});

const quotaOptions = reactive({
  fiftyPercentUsed: {
    label: t('merchantSettings.notifications.quota.fiftyPercentUsed'),
    value: notifications.value?.fiftyPercentUsed,
  },
  eightyPercentUsed: {
    label: t('merchantSettings.notifications.quota.eightyPercentUsed'),
    value: notifications.value?.eightyPercentUsed,
  },
  attemptToExceed: {
    label: t('merchantSettings.notifications.quota.attemptToExceed'),
    value: notifications.value?.attemptToExceed,
    tip: t('merchantSettings.notifications.quota.tip'),
  },
});

watch(notifications, () => {
  if (notifications?.value) {
    Object.entries(notifications.value).forEach((el) => {
      const [key, value] = el;
      if (accountOptions[key]) {
        accountOptions[key].value = value;
      }
      if (paymentOptions[key]) {
        paymentOptions[key].value = value;
      }
    });
  }
});

const onUpdateNotification = async ({ name, value }) => {
  const params = { [name]: value };
  const { isSuccess, data } = await updateNotification(params);

  if (isSuccess) {
    emit('update-data', data);
  }
};

const isPlanPaid = computed(() => user.value?.subscription?.plan !== 'FREE');
const isPlanIo = computed(() => user.value?.subscription?.plan === 'IO');
const isPlanBlack = computed(() => user.value?.subscription?.plan === accountTypes.black.title);
</script>

<style lang="scss" scoped>
.settings-notifications {
  :deep(.header) {
    margin-bottom: 20px;
  }
}
</style>
