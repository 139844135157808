<template>
  <SmoothModal
    :model-value="isModalOpen"
    width="930px"
    height="610px"
    :is-description-visible="false"
    :has-header="false"
    :has-body-paddings="false"
    @open="getData"
    @close="closeModal"
  >
    <div class="api-keys-header">
      <AppText size="17px" class="font-medium" is-block mr="40px">
        {{ t('merchantSettings.api.table.actionText') }}
      </AppText>
      <SmallActionButton
        icon="download"
        :is-animated="false"
        :is-disabled="!logs?.length"
        @action="downloadLogs"
      >
        {{ t('merchantSettings.api.logs.downloadLog') }}
      </SmallActionButton>
      <SmallActionButton @action="getData" />
    </div>
    <CustomScroll class="api-keys-scroll" :has-full-height>
      <div class="bg" :style="{ '--bg-height': bgHeight }">
        <div class="data-bg" />
        <div class="dash" />
        <div class="data font-mono">
          <template v-if="logs?.length && isLogsLoading === false">
            <div v-for="(item, i) in logs" :key="i" class="row">
              <div :key="isModalOpen" ref="idx" class="cell id" :class="{ red: item.statusCode !== 200 }">
                {{ logs.length - i }}
              </div>
              <div class="cell selectable" :class="{ red: item.statusCode !== 200 }">
                [<DateFormatter :date="item.requestDate" :format="format" is-plain-text />] {{ item.requestUrl }} {{ item.statusCode }}
              </div>
            </div>
          </template>
          <template v-else-if="!logs?.length && isLogsLoading === false">
            <div class="row">
              <div class="cell id" />
              <div class="cell" style="color: var(--color-black-04);">
                {{ t('merchantSettings.api.logs.noData') }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </CustomScroll>
  </SmoothModal>
</template>

<script setup>
import { computed, ref } from 'vue';

import SmoothModal from '@/components/SmoothModal/SmoothModal.vue';
import SmallActionButton from '@/components/Buttons/SmallActionButton.vue';
import CustomScroll from '@/components/CustomScroll.vue';

import { downloadKeyLogs, getKeyLogs } from '@/api/keyLogs';
import { useSettings } from '@/composables/useSettings';

const props = defineProps({
  keyId: {
    type: String,
    required: true,
  },
  keyName: {
    type: String,
    required: true,
  },
});

const isModalOpen = defineModel({ type: Boolean, default: false });

const logs = ref([]);
const isLogsLoading = ref(null);

const getData = async () => {
  isLogsLoading.value = true;
  logs.value = (await getKeyLogs(props.keyId)).data;
  isLogsLoading.value = false;
};

const downloadLogs = () => {
  downloadKeyLogs(props.keyId, props.keyName);
};

const { dateFormat, timeFormat } = useSettings();
const format = computed(() => `${dateFormat.value} ${timeFormat.value}`);

const closeModal = () => {
  if (isModalOpen.value) {
    isModalOpen.value = false;
    logs.value = [];
  }
};

const bgHeight = computed(() => {
  const res = (logs.value?.length || 0) * 20 + 10;
  if (res < 542) {
    return `${542}px`;
  }

  return `${res}px`;
});
</script>

<style lang="scss" scoped>
$body-height: 542px;

.api-keys-header {
  height: 68px;
  display: flex;
  align-items: center;
  padding-left: 50px;
  background-color: var(--color-F7F7F7);
}

.bg {
  position: relative;
  height: var(--bg-height);
  padding-top: 10px;
  background-color: var(--color-F7F7F7);

  .data-bg {
    width: 100%;
    position: absolute;
    top: 0;
    background-color: var(--color-white);
    height: var(--bg-height);
    border-radius: 8px 0 0 0;
    margin-left: 50px;
  }

  .dash {
    position: absolute;
    top: 0;
    min-height: 100%;
    width: 10px;
    border-radius: 6px 0 0 0;
    background-color: var(--color-white);
    border-right: 1px dashed var(--color-black-01);
    margin-left: 50px;
  }
}

.red {
  color: var(--color-error) !important;
  background-color: var(--color-error-01);
}

.data {
  position: absolute;
  width: 100%;
  font-size: 13px;
  color: var(--color-black-04);
  display: grid;
  grid-template-columns: fit-content(100%) 1fr;

  .row {
    display: contents;

    .cell {
      height: 20px;
      display: flex;
      align-items: center;
    }

    .id {
      min-width: 50px;
      padding: 0 14px 0 20px;
      justify-content: flex-end;
    }

    & > :nth-child(2) {
      padding-left: 20px;
    }
  }
}

.api-keys-scroll {
  height: $body-height;
}
</style>
