<template>
  <TransitionWrapper>
    <div class="settings-wrapper">
      <FTabs
        v-model="currentTab"
        :options="tabs"
        has-colored-titles
        height="50px"
      >
        <template #label="{ item }">
          <template v-if="item.value === 'sessions' && devicesLength">
            {{ item.text }} <AppText class="font-medium" color="var(--color-black-04)" style="padding-left: 1px;">
              {{ devicesLength }}
            </AppText>
          </template>
          <template v-else>
            {{ item.text }}
          </template>
        </template>
      </FTabs>
      <div class="settings-body">
        <div>
          <div class="tab-container">
            <TransitionGroup name="fade-absolute">
              <SettingsGeneral
                v-if="currentTab === tabs[0].value"
                :settings="settings"
                :last-updated="generalLastUpdated"
              />
              <Notifications
                v-if="currentTab === tabs[1].value"
                :last-updated="notificationsLastUpdated"
                @update-data="onGetMerchantSettings"
              />
              <Security
                v-if="currentTab === tabs[2].value"
                :last-updated="securityLastUpdated"
                :settings="settings"
                :data="activities"
                @update="onGetActivities"
              />
              <SettingsRules
                v-if="currentTab === tabs[3].value"
                :settings="settings"
                :last-updated="rulesLastUpdated"
              />
              <APIkeys
                v-if="currentTab === tabs[4].value"
                :items="apiKeys"
                @update="onGetKeys"
              />
              <SettingsSessions
                v-if="currentTab === tabs[5].value"
                :items="devices"
                @get-data="onGetDevices({ isFreshResponse: true })"
              />
            </TransitionGroup>
          </div>
        </div>

        <SettingsData v-if="currentTab === tabs[0].value" />
      </div>
    </div>
  </TransitionWrapper>
</template>

<script>
import {
  computed, onMounted, reactive, ref,
} from 'vue';

import TransitionWrapper from '@/components/Animation/TransitionWrapper.vue';

import { getMerchantProfile, getSettings } from '@/api/merchant';
import { useBus } from '@/composables/useBus';
import { useSettings } from '@/composables/useSettings';
import { i18n } from '@/plugins/localization';

import APIkeys from './blocks/APIkeys';
import SettingsGeneral from './blocks/SettingsGeneral';
import Notifications from './blocks/Notifications';
import SettingsRules from './blocks/SettingsRules';
import Security from './blocks/Security';
import SettingsSessions from './blocks/SettingsSessions';
import SettingsData from './blocks/SettingsData';
import { getDevices, getApiKeys, getActivities } from './api';

export default {
  name: 'MerchantSettings',
  components: {
    Security,
    SettingsGeneral,
    Notifications,
    SettingsSessions,
    SettingsData,
    SettingsRules,
    APIkeys,
    TransitionWrapper,
  },
  setup() {
    const { t } = i18n.global;
    const { settings: globalSettings } = useSettings();
    const settings = reactive({});
    const onGetMerchantSettings = async () => {
      const { data } = await getSettings();
      Object.assign(settings, data);
    };

    const devices = ref([]);
    const devicesLength = computed(() => (devices.value?.length ? ` (${devices.value?.length})` : ''));
    const onGetDevices = async (params = {}) => {
      const { data } = await getDevices(params);
      devices.value = data;
    };

    const apiKeys = ref([]);
    const onGetKeys = async () => {
      const { data } = await getApiKeys();
      apiKeys.value = data;
    };

    const activities = ref([]);
    const onGetActivities = async () => {
      const { data } = await getActivities();
      activities.value = data;
    };

    onMounted(() => {
      getMerchantProfile();
      onGetMerchantSettings();
      onGetDevices();
      onGetKeys();
      onGetActivities();
    });

    const { emitHandler } = useBus();
    emitHandler('update-settings-page', () => {
      getMerchantProfile();
      onGetMerchantSettings();
      onGetDevices();
      onGetKeys();
      onGetActivities();
    });

    const tabs = computed(() => [
      { text: t('merchantSettings.tab.general'), value: 'general' },
      { text: t('merchantSettings.tab.notifications'), value: 'notifications' },
      { text: t('merchantSettings.tab.security'), value: 'security' },
      { text: t('merchantSettings.tab.rules'), value: 'rules' },
      { text: t('merchantSettings.tab.api'), value: 'api' },
      { text: t('merchantSettings.tab.activeSessions'), value: 'sessions' },
    ]);

    const currentTab = ref(tabs.value[0].value);

    const generalLastUpdated = computed(() => globalSettings.value.generalUpdatedAt || settings.createdAt);
    const notificationsLastUpdated = computed(() => globalSettings.value.notificationsUpdatedAt || settings.createdAt);
    const securityLastUpdated = computed(() => globalSettings.value.securityUpdatedAt || settings.createdAt);
    const rulesLastUpdated = computed(() => globalSettings.value.rulesUpdatedAt || settings.createdAt);

    return {
      settings,

      currentTab,
      tabs,
      devices,
      devicesLength,
      apiKeys,
      activities,

      generalLastUpdated,
      notificationsLastUpdated,
      securityLastUpdated,
      rulesLastUpdated,

      onGetMerchantSettings,
      onGetDevices,
      onGetKeys,
      onGetActivities,
    };
  },
};
</script>

<style lang="scss" scoped>
.settings-wrapper {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;

  .settings-body {
    width: 100%;
    max-width: 1200px;
    padding: 0 40px;
    flex: 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 68px;
    row-gap: 40px;

    .tab-container {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  :deep(.el-tabs) {
    padding-left: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #E6E6E6;
  }

  :deep(.el-tabs__header) {
    margin-bottom: 0;
  }
}

:deep(.el-tabs__item) {
  &.is-active {
    .custom-label * {
      color: var(--color-primary);
    }
  }
  &:hover {
    .custom-label * {
      color: var(--color-primary);
    }
  }
}

</style>
