<template>
  <SmoothModal
    :model-value="isModalOpen"
    width="480px"
    :is-description-visible="false"
    :has-header="false"
    @update:model-value="closeModal"
  >
    <ActionForm
      :request-fields="requestFields"
      :validation-schema="validationSchema"
      :response-errors="fieldsErrors"
      class="add-key-form"
      @validate="onPassedValidation"
    >
      <template #default="{ errors, action }">
        <AppText size="18px" class="font-medium" variant="div" mb="16px">
          {{ t('merchantSettings.api.modal.addApi') }}
        </AppText>

        <AppText :line-height="1.5" variant="div" mb="20px">
          {{ t('merchantSettings.api.modal.description') }}
        </AppText>

        <FInput
          v-model="requestFields.memo"
          :placeholder="t('merchantSettings.api.modal.addNote')"
          :validation-error="errors.memo"
          :maxlength="41"
          autofocus
        />

        <FInput
          v-model="requestFields.callbackUrl"
          style="margin-top: 8px;"
          :placeholder="t('merchantSettings.api.modal.callbackUrl')"
          :validation-error="errors.callbackUrl"
        />

        <div class="switcher" @click.prevent="() => isIPOn = !isIPOn">
          <FSwitch
            :model-value="isIPOn"
            is-small
            type="primary"
          />

          <AppText>
            {{ t('merchantSettings.api.modal.restrictAccess') }}
          </AppText>
        </div>

        <transition name="show">
          <FInput
            v-if="isIPOn"
            v-model="requestFields.ip"
            v-maskito="maskitoOptions"
            :validation-error="errors.ip"
            :placeholder="t('merchantSettings.api.modal.addApiAddresses')"
            style="margin-top: 12px;"
          />
        </transition>

        <FButton type="primary" @click="action">
          {{ t('merchantSettings.api.modal.addApi') }}
        </FButton>
      </template>
    </ActionForm>
  </SmoothModal>
</template>

<script setup>
import { computed, ref } from 'vue';
import { maskito as vMaskito } from '@maskito/vue';

import SmoothModal from '@/components/SmoothModal';

import { maskitoRules } from '@/common/data';
import { ActionForm, useValidation, yup } from '@/validation';
import { memoRule, ipRule, urlRule } from '@/validation/rules';
import { generateApiKey } from '@/views/merchant/MerchantSettings/api';

const emit = defineEmits(['key-added']);

const isModalOpen = defineModel({ type: Boolean, default: false });
const isIPOn = ref(false);

const closeModal = () => {
  isModalOpen.value = false;
  isIPOn.value = false;
  clearState();
};

const { initState, fieldsErrors, clearState } = useValidation();

const maskitoOptions = computed(() => maskitoRules.ipAddr || {});

const fields = {
  memo: '',
  callbackUrl: '',
  ip: '',
};

const validationSchema = computed(() => yup.object().shape({
  memo: memoRule({ min: 6, max: 40 }),
  ip: isIPOn.value ? ipRule : null,
  callbackUrl: requestFields.callbackUrl ? urlRule : null,
}));

const { requestFields } = initState(fields);

const onPassedValidation = async () => {
  const { isSuccess, errorObject } = await generateApiKey({
    memo: requestFields.memo,
    ip: requestFields.ip || undefined,
    callbackUrl: requestFields.callbackUrl || undefined,
  });

  if (isSuccess) {
    emit('key-added');
    closeModal();
  }
  if (!isSuccess) {
    fieldsErrors.value = errorObject.fields;
  }
};
</script>

<style lang="scss" scoped>
.add-key-form {
  padding-top: 30px;

  .switcher {
    cursor: pointer;
    padding-top: 20px;
    display: inline-flex;
    align-items: center;
    gap: 10px;

    :deep(.el-switch) {
      height: 20px;
    }
  }

  :deep(.el-button) {
    margin-top: 30px;
  }
}
</style>
