import { getSettings } from '@/api/merchant';
import { convertBase64ToFile } from '@/utils/images';
import { useFetch } from '@/api';
import { useStore } from '@/store';
import { emitter } from '@/composables/useBus';
import { useNotifications } from '@/composables/useNotifications';

function updateProfileAvatar({ profileAvatar, profileOriginalAvatar }) {
  const store = useStore();
  store.updateProfileAvatar({ profileAvatar, profileOriginalAvatar });
}

export const saveProfilePhoto = async ({ originalImage, croppedImage }) => {
  const originalImageFile = await convertBase64ToFile(originalImage, 'profilePhoto.png', 'image/jpeg');
  const croppedImageFile = await convertBase64ToFile(croppedImage, 'croppedPhoto.png');

  const { axios } = useFetch();
  const result = await axios.postForm('/merchants/profile-picture', {
    original: originalImageFile,
    crop: croppedImageFile,
  });

  const isSuccess = result.data.success;
  const data = result.data.data;

  if (isSuccess) {
    updateProfileAvatar({
      profileAvatar: data.profilePicture.cropUrl,
      profileOriginalAvatar: data.profilePicture.originalUrl,
    });
    emitter.emit('update-settings-page');
  }

  return { isSuccess };
};

export const deleteProfilePhoto = async () => {
  const { axiosWrapper } = useFetch();
  const { isSuccess } = await axiosWrapper({
    type: 'delete',
    url: '/merchants/profile-picture',
  });

  if (isSuccess) {
    updateProfileAvatar({
      profileAvatar: '',
      profileOriginalAvatar: '',
    });
  }
  emitter.emit('update-settings-page');
  return { isSuccess };
};

export const updateRevokeSettings = async (settings) => {
  const { axiosWrapper, successHandler, t } = useFetch();
  const { isSuccess } = await axiosWrapper({
    type: 'patch',
    url: '/settings/merchant/revoke',
    params: settings,
  });

  if (isSuccess) {
    successHandler(t('merchantSettings.notification.updateRevokeSuccess'));
    getSettings();
  }

  return { isSuccess };
};

export const updateNotification = async (params) => {
  const { axiosWrapper, successHandler, t } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'patch',
    url: '/settings/merchant/notifications',
    params: {
      notifications: params,
    },
  });

  if (isSuccess) {
    successHandler(t('merchantSettings.notification.updateNotificationSuccess'));
    const store = useStore();

    store.$patch({
      user: {
        settings: {
          notifications: data.notifications,
        },
      },
    });
  }

  return { data, isSuccess };
};

export const getDevices = async (params) => {
  const { axiosWrapper } = useFetch();
  const { data, isLoading } = await axiosWrapper({
    type: 'get',
    url: '/merchants/devices',
    params,
  });

  return { data, isLoading };
};

export const updateMerchantName = async (name) => {
  const { axiosWrapper, successHandler, t } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'put',
    url: `/settings/merchant/name/${name}`,
  });

  if (isSuccess) {
    const store = useStore();

    store.$patch({
      user: {
        companyName: data.companyName,
      },
    });
    successHandler(t('merchantSettings.notification.updateMerchantNameSuccess'));
  } else {
    const { addNotification } = useNotifications();
    addNotification({
      text: t('merchantSettings.notification.updateMerchantNameFail'),
      config: { color: 'error' },
    });
  }

  return { isSuccess };
};

export const getApiKeys = async () => {
  const { axiosWrapper } = useFetch();
  const { isSuccess, data } = await axiosWrapper({
    type: 'get',
    url: '/merchants/api/keys',
    params: { isFreshResponse: true },
  });

  if (isSuccess) {
    const store = useStore();

    store.$patch({
      settings: {
        apiKeysUpdatedAt: data.apiKeysUpdatedAt,
      },
    });
  }

  return { isSuccess, data: data?.keys?.reverse() || [] };
};

export const generateApiKey = async ({ memo, ip, callbackUrl }) => {
  const { axiosWrapper, successHandler, t } = useFetch();
  const { isSuccess, errorObject } = await axiosWrapper({
    type: 'post',
    url: '/merchants/api/generate-key',
    params: {
      ip: ip || undefined,
      memo,
      callbackUrl,
    },
  });

  if (isSuccess) {
    successHandler(t('merchantSettings.notification.generateApiKeySuccess'));
  }

  return { isSuccess, errorObject };
};

export const deleteApiKey = async (id) => {
  const { axiosWrapper, successHandler, t } = useFetch();
  const { isSuccess } = await axiosWrapper({
    type: 'delete',
    url: `/merchants/api/key/${id}`,
  });

  if (isSuccess) {
    successHandler(t('merchantSettings.notification.deleteApiKeySuccess'));
  }

  return { isSuccess };
};

export const getActivities = async () => {
  const { axiosWrapper } = useFetch();
  const { data, isLoading } = await axiosWrapper({
    type: 'get',
    url: '/merchants/activities',
  });

  return { data: data.payload, isLoading };
};
